@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
/* Default */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 1.2rem;
  line-height: 2rem;
  font-family: "Montserrat", sans-serif;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

/* Utilities */
.container {
  max-width: 125rem;
  margin: 0;
  padding: 0 1.5rem;
}

.hue-shift {
  animation: hue-shift 5s infinite alternate;
}
.hue-shift-slow {
  animation: hue-shift 10s infinite alternate;
}

section {
  padding: 10rem;
}
/* Header */
header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
}

.nav {
  padding: 2rem 12rem 2rem 8rem;
  height: 10rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.nav-list {
  display: flex;
  list-style: none;
}

.nav-item {
  margin-right: 5rem;
}

.nav-item:last-child {
  margin-right: 0;
}

.nav-link {
  font-size: 1.2rem;
  text-transform: capitalize;
  color: #fff;
  transition: opacity 0.3s;
}

.nav-link:hover {
  opacity: 0.6;
}

.logo {
  margin-top: 3rem;
  display: block;
  width: 6rem;
}

/* Main Content */
.landing-page {
  width: 100%;
  min-height: 100vh;
  background-image: linear-gradient(to bottom right, #3b4679, #2b3153);
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  z-index: 1;
}

.landing-page::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: 100vh;
  background-image: url("../src/assets/bg-pat.png");
  background-size: cover;
  opacity: 0.4;
  z-index: -1;
}

.content {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
}

.content-details {
  max-width: 80%;
}

.title {
  font-size: 6rem;
  color: #fff;
  line-height: 100%;
  margin-bottom: 1rem;
  font-weight: 700;
}

.description {
  color: #929fc5;
  margin-bottom: 2.5rem;
}

.search {
  margin-bottom: 2.5rem;
}

.search input {
  border: none;
  border-radius: 5rem;
  padding: 1.5rem 2rem;
  outline: none;
  font-size: 1.1rem;
}

.search-bar {
  width: calc(100% - 0.5rem);
}

.search-btn {
  width: 10rem;
  background-image: linear-gradient(to right, #ff83d5, #ffccb0);
  color: #fff;
  cursor: pointer;
  margin-left: -10rem;
}

.search-btn:hover {
  background-image: linear-gradient(to left, #ff83d5, #ffccb0);
}

.sm-list {
  display: flex;
  max-width: 300px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.sm-link {
  color: #929fc5;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
}

.sm-link:hover {
  border: solid 2px;
  background-image: linear-gradient(to right, #a971ff, #00f0ff);
  color: #fff;
}

.image-group {
  text-align: center;
  position: relative;
}

.blob-large {
  animation: move-up 2s infinite alternate;
}

.blob-bean {
  position: absolute;
  top: 2.5rem;
  left: -1rem;
  z-index: 10;
  animation: move-down 2s infinite alternate;
}

.blob {
  position: absolute;
  z-index: -1;
}

.blob-1 {
  top: -10rem;
  left: 50%;
  transform: translateX(-50%);
}

.blob-2 {
  top: 15rem;
  left: -10rem;
}

.blob-3 {
  right: 3rem;
  bottom: -13rem;
}

.blob-4 {
  position: absolute;
  left: -1rem;
  bottom: -12rem;
  animation: move-up 3s infinite alternate;
}

/* Animation */

@keyframes move-up {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(2rem);
  }
}

@keyframes move-down {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-2rem);
  }
}

@keyframes hue-shift {
  from {
    filter: hue-rotate(0);
  }
  to {
    filter: hue-rotate(360deg);
  }
}

/* Media Queries */
@media screen and (max-width: 1260px) {
  .nav {
    padding: 0 2rem 0rem 2rem;
  }
  section {
    padding-left: 3rem;
    padding-right: 0;
  }
  .title {
    margin-top: 4rem;
    font-size: 6rem;
  }
  .description {
    font-size: 1.1rem;
  }
  .blob-4 {
    bottom: -15rem;
  }
  .search-btn {
    width: 6rem;
    margin-left: -6rem;
  }
}

@media screen and (max-width: 770px) {
  .nav {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .logo {
    margin-top: 0;
    width: 5rem;
    margin-left: 4rem;
  }
  .content {
    grid-template-columns: 1fr;
  }
  .content-details {
    order: 1;
    margin-top: 4rem;
    max-width: 100%;
    padding-left: 3rem;
  }

  .title {
    font-size: 3rem;
  }

  .image-group {
    margin-top: 3rem;
    margin-left: 4rem;
    margin-right: 0;
  }

  .blob-large {
    width: 80%;
  }

  .blob-bean {
    width: 30%;
    top: -1rem;
    left: 5rem;
  }

  .search-btn {
    width: 10rem;
    margin-left: -10rem;
  }

  .blob-1 {
    left: -2rem;
    top: 43rem;
  }

  .blob-2 {
    left: 30rem;
    bottom: -4rem;
    top: revert;
    width: 30%;
  }

  .blob-3 {
    right: -10rem;
    bottom: 25rem;
    width: 40%;
    transform: rotate(180deg);
  }

  .blob-4 {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .nav {
    flex-direction: row;
    padding: 2rem 2rem;
  }
  .logo {
    width: 4rem;
    margin-left: 0;
  }

  .nav-item {
    margin-right: 2.5rem;
  }

  .blob-2 {
    display: none;
  }

  .blob-3 {
    width: 50%;
    bottom: -10rem;
  }
}

@media screen and (max-width: 450px) {
  .logo {
    display: none;
  }
  .nav {
    justify-content: center;
  }
  .search-btn {
    width: 6rem;
    margin-left: -6rem;
  }
  .image-group {
    max-width: fit-content;
  }
}
